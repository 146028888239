import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  categoryId: null,
  categoryWithServices: null,
  services: null,
});

const state = getDefaultState();
const getters = {
  getCategoryId: (state) => state.categoryId,
  categoryWithServices: (state) =>
    state.categoryWithServices ? state.categoryWithServices : [],
  getServices: (state) => (state.services ? state.services : []),
};
const actions = {
  add({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createCategory(payload)
        .then(({ data }) => {
          commit("SET_CATEGORY_ID", data.id);
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });

          dispatch("fetchWithServices").then(() => {
            resolve(data);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateCategory(payload, payload.id)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });

          dispatch("fetchWithServices").then(() => {
            resolve(data);
          });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  delete({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteCategory(id)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });

          dispatch("fetchWithServices").then(() => {
            resolve(data);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchForServices(context, data) {
    return new Promise((resolve, reject) => {
      api
        .categoriesAndServices(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchWithServices({ commit }) {
    // This supposes to have always all categories&services.
    return new Promise((resolve, reject) => {
      api
        .categoriesAndServices()
        .then(({ data }) => {
          commit("SET_CATEGORIES_WITH_SERVICES", data);

          const services = [];

          data.forEach((category) => {
            if (category.services != null) {
              services.push(...category.services);
            }
          });

          commit("SET_SERVICES", services);

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CATEGORY_ID(state, id) {
    state.categoryId = id;
  },
  SET_CATEGORIES_WITH_SERVICES(state, data) {
    state.categoryWithServices = data;
  },
  SET_SERVICES(state, data) {
    state.services = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
