import Vue from "vue";
import Vuex from "vuex";

import * as common from "./common";
/**
 * modules
 */
import setup from "./modules/setup";
import dashboard from "./modules/dashboard";
import auth from "./modules/auth";
import user from "./modules/user";
import tag from "./modules/tag";
import cache from "./modules/cache";
import packages from "@/store/modules/packages";
import payments from "@/store/modules/payments";
import category from "@/store/modules/category";
import employee from "@/store/modules/employee";
import stripe from "@/store/modules/stripe";
import webDraft from "@/store/modules/webDraft";
import mobileDraft from "@/store/modules/mobileDraft";
import dialog from "@/lib/calendesk-js-library/store/dialog";
import confirmDialog from "@/lib/calendesk-js-library/store/confirmDialog";
import loader from "@/lib/calendesk-js-library/store/loader";
import availabilitySchedule from "@/store/modules/availabilitySchedule";
import notification from "@/store/modules/notification";
import calendar from "@/store/modules/calendar";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
export const strict = false;
export default new Vuex.Store({
  strict: debug,
  state: {
    ...common.state,
  },
  mutations: {
    ...common.mutations,
  },
  getters: {
    ...common.getters,
  },
  actions: {
    ...common.actions,
  },
  modules: {
    setup,
    cache,
    dashboard,
    auth,
    user,
    dialog,
    tag,
    category,
    confirmDialog,
    loader,
    packages,
    payments,
    employee,
    stripe,
    webDraft,
    mobileDraft,
    availabilitySchedule,
    notification,
    calendar,
  },
});
