import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default (to) => {
  if (to && (to.name === "card3ds" || to.name === "browser")) {
    return;
  }

  const title = to.name === "tenant" ? "login" : to.name;
  document.title = `${trans(title)} | Calendesk`;
};
