import moment from "@/lib/calendesk-js-library/plugins/moment";
import store from "../../store";

export default function (value) {
  if (!value) {
    return "";
  }
  return moment(value)
    .locale(store.state.setup.configuration.language)
    .fromNow();
}
