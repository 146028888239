<template>
  <v-container class="form__container" fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="8">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 text-uppercase" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h5">{{
                $trans("required_permission_info")
              }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col class="d-flex align-center flex-wrap">
              <v-btn outlined x-large @click="$emit('close')">
                {{ $trans("close") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="d-none d-md-flex align-center justify-center"
        cols="12"
        md="4"
      >
        <v-img
          :src="require('@/lib/calendesk-js-library/assets/verification.png')"
          class="ma-2"
          contain
          max-width="200"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RequiredPermissionDialog",
};
</script>

<style lang="scss"></style>
