import has from "lodash.has";
import afterGuard from "./afterGuard";
import beforeGuard from "./beforeGuard";

const guards = {
  afterGuard,
  beforeGuard,
};

export const createGuard = (name) => {
  if (has(guards, name)) {
    return guards[name];
  }
};
