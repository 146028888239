import router from "@/router";
import { tokenOperations } from "@/lib/calendesk-js-library/tools/token";
import unregisterModules from "@/store/unregisterModules";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  onLoginUserAndStoreSessionSuccess(commit, data) {
    this.cleanAppStorage(commit);
    this.cleanSessionInLocalStorage();

    tokenOperations(data);
    commit("SET_IS_USER_LOGGED", true);
    commit("SET_IS_SENDING", false, { root: true });

    if (data.issuer) {
      commit("auth/SET_CD_ADMIN_SESSION_ISSUER", data.issuer, { root: true });
    }

    pushEvent("login");
  },
  cleanAppStorage(commit) {
    unregisterModules();
    commit("setup/RESET_CONFIGURATION", null, { root: true });
    commit("setup/RESET_ADMIN_CONFIGURATION", null, { root: true });
    commit("payments/RESET_STATE", null, { root: true });
    commit("employee/RESET_STATE", null, { root: true });
    commit("packages/RESET_STATE", null, { root: true });
    commit("stripe/RESET_STATE", null, { root: true });
    commit("webDraft/RESET_STATE", null, { root: true });
    commit("user/RESET_STATE", null, { root: true });
    commit("tag/RESET_STATE", null, { root: true });
    commit("category/RESET_STATE", null, { root: true });
    commit("availabilitySchedule/RESET_STATE", null, { root: true });
    commit("setup/SET_PRELOADER", false, { root: true });
    commit("notification/RESET_STATE", null, { root: true });
    commit("cache/RESET_STATE", null, { root: true });
    commit("calendar/RESET_STATE", null, { root: true });
    commit("auth/RESET_STATE", null, { root: true });
  },
  cleanSessionInLocalStorage() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpiresAt");
    localStorage.removeItem("cd_admin_session_issuer");
  },
  onLogoutUserAndDestroySession(commit) {
    pushEvent("logout");

    router.push({ name: "login" }).finally(() => {
      localStorage.removeItem("tenant");
      this.cleanAppStorage(commit);
    });

    this.cleanSessionInLocalStorage();
    commit("SET_IS_USER_LOGGED", false);
  },
  onGetUserDataSuccess(commit, data) {
    commit("SET_USER", data);
    commit("SET_IS_SENDING", false, { root: true });
  },
  onGetUserDataFail(commit) {
    commit("SET_IS_USER_LOGGED", false);
    commit("SET_IS_SENDING", false, { root: true });
  },
};
