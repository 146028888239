import DOMPurify from "dompurify";

const sanitizeOptions = {
  ALLOWED_TAGS: [
    "html",
    "head",
    "meta",
    "title",
    "style",
    "body",
    "table",
    "tr",
    "td",
    "div",
    "img",
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "ul",
    "ol",
    "li",
    "hr",
    "br",
    "span",
    "strong",
    "b",
    "i",
    "u",
    "strike",
    "s",
    "pre",
    "code",
    "mark",
    "em",
  ],
  ALLOWED_ATTR: [
    "href",
    "src",
    "alt",
    "style",
    "class",
    "role",
    "border",
    "cellpadding",
    "cellspacing",
    "width",
    "height",
    "align",
    "name",
    "content",
    "http-equiv",
  ],
  FORCE_BODY: true,
};
export default function (value) {
  return DOMPurify.sanitize(value, sanitizeOptions);
}
