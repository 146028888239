import api from "@/lib/calendesk-js-library/api/api";
import EditorContentController from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/EditorContentController";

const getDefaultState = () => ({
  types: null,
  templates: null,
  editorModalVisible: false,
  editorModalSelectedRecord: null,
  editorModalPreviewContent: null,
  usage: null,
});

const state = getDefaultState();

const getters = {
  getTypes: (state) => state.types,
  getTemplates: (state) => state.templates,
  getEditorModalVisible: (state) => state.editorModalVisible,
  getEditorModalSelectedRecord: (state) => state.editorModalSelectedRecord,
  getEditorModalPreviewContent: (state) => state.editorModalPreviewContent,
  getUsage: (state) => state.usage,
};

const actions = {
  setEditorModalVisible({ commit }, editorModalVisible) {
    commit("SET_EDITOR_MODAL_VISIBLE", editorModalVisible);
  },
  setEditorModalSelectedRecord({ commit }, type) {
    commit("SET_EDITOR_MODAL_SELECTED_RECORD", type);
  },
  setEditorModalPreviewContent({ commit }, editorModalPreviewContent) {
    commit("SET_EDITOR_MODAL_PREVIEW_CONTENT", editorModalPreviewContent);
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateTenantPackagePreferences(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTypes({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getNotificationTemplateTypes()
        .then(({ data }) => {
          commit("SET_TYPES", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUsage({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getNotificationsUsage()
        .then(({ data }) => {
          commit("SET_USAGE", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getNotificationTemplates()
        .then(({ data }) => {
          commit("SET_TEMPLATES", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNotificationTemplate(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .createNotificationTemplate(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNotificationTemplate(context, templateId) {
    return new Promise((resolve, reject) => {
      api
        .deleteNotificationTemplate(templateId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNotificationTemplate(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateNotificationTemplate(payload.template_id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TYPES(state, types) {
    state.types = types;
  },
  SET_USAGE(state, usage) {
    state.usage = usage;
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_EDITOR_MODAL_VISIBLE(state, editorModalVisible) {
    state.editorModalVisible = editorModalVisible;
  },
  SET_EDITOR_MODAL_SELECTED_RECORD(state, editorModalSelectedRecord) {
    state.editorModalSelectedRecord = editorModalSelectedRecord;
    state.editorModalPreviewContent =
      EditorContentController.generateFreshContent(
        state.editorModalSelectedRecord
      );
  },
  SET_EDITOR_MODAL_PREVIEW_CONTENT(state, editorModalPreviewContent) {
    state.editorModalPreviewContent = editorModalPreviewContent;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
