import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  data: null,
  refreshTagsStatus: false,
  newTag: null,
});

const state = getDefaultState();
const getters = {
  tags: ({ data }) => {
    if (data !== null) {
      data = data.data;
    } else {
      data = [];
    }

    return data;
  },
  shouldRefreshTags: (state) => state.refreshTagsStatus,
  getNewTag: (state) => state.newTag,
};
const actions = {
  fetchAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .tags({
          limit: 999999,
        })
        .then(({ data }) => {
          commit("SET_DATA", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPaginated(context, data) {
    return new Promise((resolve, reject) => {
      api
        .tags(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, name) {
    return new Promise((resolve, reject) => {
      api
        .createTag({ name })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateTag(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, tagId) {
    return new Promise((resolve, reject) => {
      api
        .deleteTag(tagId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshTags({ dispatch, commit }) {
    dispatch("cache/clearCache", "tag-list", {
      root: true,
    });

    commit("SET_REFRESH_TAGS", true);
    setTimeout(() => {
      commit("SET_REFRESH_TAGS", false);
    }, 1000);
  },
  setNewTag({ commit }, tag) {
    commit("SET_NEW_TAG", tag);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_REFRESH_TAGS(state, value) {
    state.refreshTagsStatus = value;
  },
  SET_NEW_TAG(state, tag) {
    state.newTag = tag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
