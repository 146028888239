import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default function (value, key, lowercase = false, separator = " ") {
  const keyValue = value > 5 ? 5 : value;
  const result = value + separator + trans(`${key}_plural_${keyValue}`);

  if (lowercase) {
    return result.toLowerCase();
  }

  return result;
}
