import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "@/lib/calendesk-js-library/styles/colors";
import "@/styles/styles.scss";
import icons from "@/styles/icons";
import { pl, en, de, fr, es, ru } from "vuetify/lib/locale";

Vue.use(Vuetify);

const theme = {
  ...colors,
};

export default new Vuetify({
  lang: {
    locales: { pl, en, de, fr, es, ru },
    current: "en",
  },
  icons: {
    iconfont: "mdiSvg",
    values: icons,
  },
  theme: {
    dark: false,
    themes: {
      light: theme,
    },
    options: {
      customProperties: true,
    },
  },
});
