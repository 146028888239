<template>
  <v-container class="white fill-height">
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h4">{{ title }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h6">{{ subtitle }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p v-html="description1" />
              <p v-html="description2" />
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col class="d-flex align-center flex-wrap">
              <v-btn outlined x-large @click="reloadPage">
                {{ reload }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-none d-md-flex align-center justify-center" cols="6">
        <v-img
          :src="require('@/lib/calendesk-js-library/assets/error.png')"
          class="ma-2"
          contain
          max-height="600"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "GlobalError",
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getGlobalErrorCode: "setup/getGlobalErrorCode",
    }),
    title() {
      const translation = this.$trans("global_error_title");

      if (translation === "global_error_title") {
        return "Sorry, there was a problem while loading Calendesk.";
      }

      return translation;
    },
    subtitle() {
      const translation = this.$trans("global_error_subtitle");

      if (translation === "global_error_subtitle") {
        return "This problem usually occurs for two reasons:";
      }

      return translation;
    },
    description1() {
      const translation = this.$trans("global_error_description_1");

      if (translation === "global_error_description_1") {
        return "1. <b>Internet connection</b>. Try to check your internet connection and reload the page.";
      }

      return translation;
    },
    description2() {
      const translation = this.$trans("global_error_description_2");

      if (translation === "global_error_description_2") {
        return "2. <b>We are updating Calendesk</b>. Sometimes we need to update the system to add new features or fix some bugs. Please try reloading the page in a few minutes.";
      }

      return translation;
    },
    reload() {
      const translation = this.$trans("reload");

      if (translation === "reload") {
        return "Reload";
      }

      return translation;
    },
  },
  created() {
    pushEvent("globalError", {
      locale: localStorage.locale,
      code: this.getGlobalErrorCode,
    });
    this.clearLocalStorage();
  },
  methods: {
    reloadPage() {
      this.clearLocalStorage();
      location.reload();
    },
    clearLocalStorage() {
      const locale = localStorage.locale;
      localStorage.clear();
      localStorage.locale = locale;
    },
  },
};
</script>

<style lang="scss"></style>
