import store from "../../../store";
import log from "../tools/log";

export const adminConfig = (key) => {
  if (
    store &&
    store.state &&
    store.state.setup &&
    store.state.setup.adminConfiguration
  ) {
    const config = store.state.setup.adminConfiguration[key];

    if (typeof config !== "undefined") {
      return config;
    }

    if (config === undefined) {
      log.warn("Admin configuration missing!", key);
    }
  }

  return null;
};
