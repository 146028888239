import { config } from "@/lib/calendesk-js-library/prototypes/config";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default function (value) {
  if (!value) {
    return "";
  }

  const variables = [
    { key: "EMPLOYEE_ID", data: 1 },
    { key: "EMPLOYEE_NAME", data: trans("employee_example_name") },
    { key: "EMPLOYEE_SURNAME", data: trans("employee_example_surname") },
    { key: "EMPLOYEE_FULL_NAME", data: trans("employee_example_fullname") },
    { key: "CUSTOMER_FULL_NAME", data: trans("customer_example_fullname") },
    { key: "EMPLOYEE_EMAIL", data: trans("wb_default_email") },
    { key: "EMPLOYEE_PHONE", data: trans("wb_default_phone") },
    { key: "CUSTOMER_ID", data: 8753 },
    { key: "CUSTOMER_NAME", data: trans("customer_example_name") },
    { key: "CUSTOMER_SURNAME", data: trans("customer_example_surname") },
    { key: "CUSTOMER_EMAIL", data: trans("wb_default_email") },
    { key: "CUSTOMER_PHONE", data: trans("wb_default_phone") },
    { key: "BOOKING_ID", data: "1" },
    { key: "BOOKING_START_DATE", data: "2024-01-21" },
    { key: "BOOKING_START_TIME", data: "12:00" },
    { key: "BOOKING_END_DATE", data: "2024-01-21" },
    {
      key: "BOOKING_RECURRENCE_DESCRIPTION",
      data: trans("booking_recurrence_description"),
    },
    { key: "BOOKING_LOCATION", data: trans("service_location_3") },
    { key: "BOOKING_TIME_ZONE", data: config("time_zone") },
    {
      key: "BOOKING_ONLINE_MEETING_URL",
      data: "https://meeting-online.com/abc-1234-cde",
    },
    {
      key: "BOOKING_GOOGLE_MEET_URL",
      data: "https://meet.google.com/abc-1234-cde",
    },
    {
      key: "BOOKING_ZOOM_JOIN_URL",
      data: "https://zoom.us/j/91717684964?pwd=abcdefyw95BNejhxUC9YRzBoU3Iyd109",
    },
    { key: "BOOKING_CONTROL_URL", data: trans("booking_control_url_example") },

    { key: "BOOKING_CREATED_BY_USER_ID", data: 1 },
    { key: "BOOKING_UPDATED_BY_USER_ID", data: 1 },
    { key: "BOOKING_CREATED_BY", data: trans("employee_example_fullname") },
    { key: "BOOKING_UPDATED_BY", data: trans("employee_example_fullname") },

    {
      key: "BOOKING_CUSTOM_FIELDS",
      data: trans("custom_fields_example"),
    },
    { key: "BOOKING_END_TIME", data: "11:00" },
    { key: "BOOKING_AWAITING_PAYMENT_TIME", data: 30 },
    { key: "BOOKING_AWAITING_PAYMENT_FULL_TIME", data: "30 min" },
    { key: "BOOKING_PAYMENT_STATUS", data: trans("paid") },
    { key: "SERVICE_ID", data: 15 },
    { key: "SERVICE_NAME", data: trans("service_example_name") },
    { key: "BOOKING_PRICE", data: trans("default_price") },
    { key: "COMPANY_NAME", data: config("company_name") },
    {
      key: "COMPANY_PHONE",
      data: config("company_phone") ?? trans("phone_number_is_missing"),
    },
    {
      key: "COMPANY_ADDRESS",
      data: config("company_address") ?? trans("company_address_is_missing"),
    },
    { key: "BOOKING_STATUS", data: trans("approved") },
    { key: "NEWSLETTER_EMAIL", data: trans("wb_default_email") },
    { key: "PRODUCT_ID", data: 1 },
    {
      key: "PRODUCT_DESCRIPTION",
      data: trans("wb_default_product_description"),
    },
    { key: "PRODUCT_NAME", data: trans("wb_default_product_name") },
    { key: "PRODUCT_PRICE", data: trans("default_price") },
    { key: "PRODUCT_QUANTITY", data: "1" },
    {
      key: "PRODUCT_LIST",
      data: `<h3 class="mb0 mt20">${trans("additional_products")}:</h3>

    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="mb30">
        <thead>
        <tr>
            <th class="table-column table-long-text-column">${trans(
              "simple_store_product",
            )}</th>
            <th class="table-column">${trans("price")}</th>
            <th class="table-column table-long-text-column">${trans(
              "additional_information",
            )}</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td class="table-column table-long-text-column">${trans(
                  "simple_product_name_example",
                )}</td>
                <td class="table-column">100,00 ${config("currency")}</td>
                <td class="table-column table-long-text-column">${trans(
                  "simple_product_description_example",
                )}</td>
            </tr>
                        <tr>
                <td class="table-column table-long-text-column">${trans(
                  "simple_product_name_example",
                )}</td>
                <td class="table-column">200,00 ${config("currency")}</td>
                <td class="table-column table-long-text-column">${trans(
                  "simple_product_description_example",
                )}</td>
            </tr>
        </tbody>
    </table>`,
    },
    {
      key: "ADD_TO_CALENDAR_BUTTONS",
      data: `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn">
        <tbody>
        <tr>
            <td align="center">
                <table role="presentation" border="0" cellpadding="0" cellspacing="10">
                    <tbody>
                    <tr>
                        <td>
                            <a target="_blank"
                               href="#">
                                ${trans("add_to_google_calendar_button")}
                            </a>
                        </td>
                        <td>
                            <a target="_blank"
                               href="#">
                                ${trans("add_to_google_ical_outlook_button")}
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>`,
    },
    {
      key: "MANAGE_CUSTOMER_BOOKING_BUTTON",
      data: `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
    <tbody>
    <tr>
        <td align="center">
            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tbody>
                <tr>
                    <td>
                        <a href="#">
                            ${trans("manage_booking_button_title")}
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    </tbody>
</table>`,
    },
    {
      key: "MANAGE_EMPLOYEE_BOOKING_BUTTON",
      data: `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                <tbody>
                <tr>
                    <td align="center">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr>
                                <td>
                                    <a href="#">
                                        ${trans("manage_bookings_button_title")}
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
              </table>`,
    },
  ];

  variables.forEach((object) => {
    value = value.replaceAll(`[${object.key.toUpperCase()}]`, object.data);
  });

  return value;
}
