import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  account: null,
  stripeConnectProducts: [],
});

const state = getDefaultState();
const getters = {
  getAccount: (state) => state.account,
  getStripeConnectProducts: (state) => state.stripeConnectProducts,
};
const actions = {
  fetchAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getStripeAccount(data)
        .then(({ data }) => {
          commit("SET_ACCOUNT", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createStripeAccount(data)
        .then(({ data }) => {
          commit("SET_ACCOUNT", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAccount({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .deleteStripeAccount()
        .then(({ data }) => {
          commit("SET_ACCOUNT", null);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchConnectProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getStripeConnectProducts(data)
        .then(({ data }) => {
          commit("SET_STRIPE_CONNECT_PRODUCTS", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createStripeProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createStripeConnectProduct(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createStripeProductPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createStripeConnectProductPrice(data.product_id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ACCOUNT(state, account) {
    state.account = account;
  },
  SET_STRIPE_CONNECT_PRODUCTS(state, products) {
    if (products) {
      state.stripeConnectProducts = products;
    } else {
      state.stripeConnectProducts = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
