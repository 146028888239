import api from "@/lib/calendesk-js-library/api/api";
import UserResponseHandler from "@/store/ResponseHandlers/UserResponseHandler";
import { resolveUsername } from "@/lib/calendesk-js-library/resolvers/AuthResolver";
import { tokenOperations } from "@/lib/calendesk-js-library/tools/token";
import {
  getItem,
  removeItem,
  setItem,
} from "@/lib/calendesk-js-library/tools/localStorageManager";

const getDefaultState = () => ({
  isUserLogged: false,
  user: null,
  firstRouteRedirectName: "calendar",
  cdAdminSessionIssuer: null,
});

const state = getDefaultState();

const getters = {
  getUserId: ({ user }) => (user !== null ? user.id : null),
  getUser: (state) => {
    return {
      ...state.user,
      employee: state.user && state.user.employee ? state.user.employee : {},
      // Kind of workaround, for legacy cases, where admin accounts didn't have an employee account.
    };
  },
  getUsername: ({ user }) => resolveUsername(user),
  isUserLogged: ({ isUserLogged }) => isUserLogged,
  getDefaultImage: ({ user }) =>
    user !== null ? user.default_image.name : user,
  getFirstRouteRedirectName() {
    if (state.user && state.user.roles) {
      const filteredRoles = state.user.roles.map((role) => role.name);

      if (
        filteredRoles.includes("root") ||
        filteredRoles.includes("admin") ||
        filteredRoles.includes("manager")
      ) {
        return "dashboard";
      }
    }

    return "calendar";
  },
  isAdmin() {
    if (state.user) {
      const filteredRoles = state.user.roles.map((role) => role.name);

      return filteredRoles.includes("root") || filteredRoles.includes("admin");
    }

    return false;
  },
  isRoot() {
    if (state.user) {
      const filteredRoles = state.user.roles.map((role) => role.name);

      return filteredRoles.includes("root");
    }

    return false;
  },
  isEmployee() {
    return !!state.user.employee;
  },
  getCdAdminSessionIssuer() {
    return (
      state.cdAdminSessionIssuer ||
      getItem("cd_admin_session_issuer", false, false)
    );
  },
};
const actions = {
  loginUserAndStoreSession(context, data) {
    return new Promise((resolve, reject) => {
      api
        .login(
          {
            email: data.email,
            password: data.password,
          },
          data.captchaToken,
        )
        .then(({ data }) => {
          UserResponseHandler.onLoginUserAndStoreSessionSuccess(
            context.commit,
            data,
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logoutUserAndDestroySession({ commit }) {
    return new Promise((resolve) => {
      api
        .logout()
        .catch(() => {
          // Ignore the error
        })
        .finally(() => {
          UserResponseHandler.onLogoutUserAndDestroySession(commit);
          resolve();
        });
    });
  },
  activationAccount({ commit }, token) {
    return new Promise((resolve, reject) => {
      api
        .activateAccount(token)
        .then(({ data }) => {
          tokenOperations(data);
          commit("SET_IS_USER_LOGGED", true);
          commit("SET_IS_SENDING", false, { root: true });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .usersData()
        .then(({ data }) => {
          UserResponseHandler.onGetUserDataSuccess(commit, data);
          resolve(data);
        })
        .catch((error) => {
          UserResponseHandler.onGetUserDataFail(commit, error);
          reject(error);
        });
    });
  },
  setIsCdAdminSessionIssuer({ commit }, value) {
    commit("SET_CD_ADMIN_SESSION_ISSUER", value);
  },
  getLogs(context, data) {
    return new Promise((resolve, reject) => {
      api
        .logs(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetForgottenPassword({ commit }, email) {
    return new Promise((resolve, reject) => {
      api
        .forgottenPassword(email)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .resetPassword(
          {
            password: data.password,
          },
          data.token,
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createAuthTokenWithSessionId(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .createAuthTokenWithSessionId(payload)
        .then(async ({ data }) => {
          setItem("ignore_tracking", true, false, true);
          UserResponseHandler.onLoginUserAndStoreSessionSuccess(
            context.commit,
            data,
          );

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CD_ADMIN_SESSION_ISSUER(state, value) {
    state.cdAdminSessionIssuer = value;

    if (!value) {
      removeItem("cd_admin_session_issuer", false);
    } else {
      setItem("cd_admin_session_issuer", value, false, false);
    }
  },
  SET_USER(state, user) {
    state.user = user;
    state.firstRouteRedirectName = getters.getFirstRouteRedirectName();
    setItem("user_id", user.id, false, false);
  },
  SET_IS_USER_LOGGED(state, status) {
    localStorage.isUserLogged = status;
    state.isUserLogged = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
