<template>
  <calendesk-information-message
    color="orange"
    additional-class="white--text my-4"
    icon-color="white"
    icon="$alert"
  >
    <slot></slot>
  </calendesk-information-message>
</template>

<script>
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "CalendeskWarningMessage",
  components: {
    CalendeskInformationMessage,
  },
};
</script>
