import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  selectedUser: null,
  newUser: null,
  refreshUsersStatus: false,
  refreshUserPayments: false,
  users: [],
});

const state = getDefaultState();
const getters = {
  getUsers: (state) => state.users,
  getSelectedUser: (state) => state.selectedUser,
  shouldRefreshUsers: (state) => state.refreshUsersStatus,
  shouldRefreshUserPayments: (state) => state.refreshUserPayments,
  getNewUser: (state) => state.newUser,
};
const actions = {
  fetch({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .users(data)
        .then(({ data }) => {
          commit("SET_USERS", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  search(context, data) {
    return new Promise((resolve, reject) => {
      api
        .usersSearch(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  get({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .user(id)
        .then(({ data }) => {
          commit("SET_SELECTED_USER", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  add(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .createUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateUser(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateOwnUser(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateOwnUser(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePreferences(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateUserPreferences(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMultiple(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateMultipleUsers(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMultiple(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteMultipleUsers(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteUser(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadAvatarAdmin(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .uploadAvatarAdmin(payload.image, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCompanyData(context, data) {
    return new Promise((resolve, reject) => {
      api
        .companyData(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  refreshUsers({ dispatch, commit }) {
    dispatch("cache/clearCache", "user-list-v2", {
      root: true,
    });

    commit("SET_REFRESH_USERS", true);
    setTimeout(() => {
      commit("SET_REFRESH_USERS", false);
    }, 1000);
  },
  refreshUserPayments({ commit }) {
    commit("SET_REFRESH_USER_PAYMENTS", Math.floor(Math.random() * 10000000));
  },
  fetchFile(context, data) {
    return new Promise((resolve, reject) => {
      api
        .generateFileRecords("users", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setSelectedUser({ commit }, user) {
    commit("SET_SELECTED_USER", user);
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_SELECTED_USER(state, user) {
    state.selectedUser = user;
  },
  SET_NEW_USER(state, user) {
    state.newUser = user;
  },
  SET_REFRESH_USERS(state, value) {
    state.refreshUsersStatus = value;
  },
  SET_REFRESH_USER_PAYMENTS(state, value) {
    state.refreshUserPayments = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
