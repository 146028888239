import moment from "@/lib/calendesk-js-library/plugins/moment";
import {
  platformTimeZone,
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";

import { adminConfig } from "@/lib/calendesk-js-library/prototypes/adminConfig";

export default function (value, format) {
  if (!value) {
    return "";
  }

  const platformTz = platformTimeZone();
  const userTz = userTimeZone();

  let result = moment(value).tz(platformTz).clone().tz(userTz).format(format);

  if (adminConfig("app_employees_time_zone_enabled")) {
    result = result + " (" + userTz + ")";
  }

  return result;
}
