<template>
  <v-container class="form__container" fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 text-uppercase" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h5">{{
                $trans("notification_usage_not_available_info")
              }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col class="d-flex align-center flex-wrap">
              <v-btn
                color="blue"
                dark
                depressed
                x-large
                class="mr-2 mt-4"
                @click="goToNotifications"
              >
                {{ $trans("buy_notifications") }}
              </v-btn>
              <v-btn outlined x-large class="mt-4" @click="$emit('close')">
                {{ $trans("close") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" class="d-none d-md-flex align-center justify-center">
        <v-img
          contain
          class="ma-2"
          :src="require('@/lib/calendesk-js-library/assets/upgrade.png')"
          max-width="400"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotificationUsageNotAvailableDialog",
  methods: {
    goToNotifications() {
      this.$emit("close");
      const route = this.$router.resolve({
        name: "notifications",
        params: { tab: "settings" },
      });
      const absoluteURL = new URL(route.href, window.location.origin).href;

      window.open(absoluteURL, "_blank");
    },
  },
};
</script>

<style lang="scss"></style>
