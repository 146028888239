import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  schedules: [],
  refreshSchedulesListStatus: 0,
});

const state = getDefaultState();
const getters = {
  schedules: (state) => state.schedules,
  getRefreshSchedulesListStatus: (state) => state.refreshSchedulesListStatus,
};
const actions = {
  fetchAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAvailabilitySchedules({ limit: 9999 })
        .then(({ data }) => {
          commit("SET_AVAILABILITY_SCHEDULES", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPaginated(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getAvailabilitySchedules(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createAvailabilitySchedule(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateAvailabilitySchedule(data.id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, scheduleId) {
    return new Promise((resolve, reject) => {
      api
        .deleteAvailabilitySchedule(scheduleId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshSchedulesList({ commit, dispatch }) {
    dispatch("cache/clearCache", "availability-list", {
      root: true,
    });

    commit(
      "SET_REFRESH_SCHEDULES_LIST_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_REFRESH_SCHEDULES_LIST_STATUS(state, value) {
    state.refreshSchedulesListStatus = value;
  },
  SET_AVAILABILITY_SCHEDULES(state, schedules) {
    if (schedules && schedules.length > 0) {
      state.schedules = schedules;
    } else {
      state.schedules = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
