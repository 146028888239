export default {
  home: "/",
  register: "register",
  password_reset: "password-reset",
  account_activation: "account-activation",
  creator: "creator",
  preview: "preview",

  panel: "panel",
  dashboard: "dashboard",
  calendar: "calendar",

  sales: {
    main: "sales",
  },

  offer: {
    main: "offer",
    services: "offer/services",
    services_add: "offer/services/add",
  },

  customers: {
    main: "customers",
    add: "customers/add",
  },

  employees: {
    main: "employees",
    add: "employees/add",
  },

  availability: {
    main: "availability/list",
  },

  plugins: {
    main: "plugins",
  },

  tools: {
    main: "tools",
    mobile_editor: "tools/app/editor",
  },

  notifications: {
    main: "notifications",
  },

  integrations: {
    main: "integrations",
  },

  settings: {
    main: "settings",
  },

  billing: {
    main: "billing",
  },
};
