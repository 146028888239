import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  tenantPackages: null,
  currentTenantPlan: null,
  plans: null,
});

const getters = {
  getCurrentTenantPlan: (state) => state.currentTenantPlan,
  getPlans: (state) => state.plans,
};

const state = getDefaultState();

const actions = {
  fetchPlans({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getPlans(data)
        .then(({ data }) => {
          commit("SET_PLANS", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCurrentTenantPlan({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getCurrentTenantPlan()
        .then(({ data }) => {
          commit("SET_CURRENT_TENANT_PLAN", data[0]);
          resolve(data[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyPromoCode(context, data) {
    return new Promise((resolve, reject) => {
      api
        .verifyPromoCode(data.code, data.period)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  packagesPurchase(context, data) {
    return new Promise((resolve, reject) => {
      api
        .packagesPurchase(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateConfiguration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateTenantPackageConfiguration(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  validateFakturowniaSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .validateFakturowniaSettings(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PLANS(state, data) {
    state.plans = data;
  },
  SET_ALL_TENANT_PACKAGES(state, data) {
    state.tenantPackages = data;
  },
  SET_CURRENT_TENANT_PLAN(state, data) {
    state.currentTenantPlan = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
