import store from "@/store/index";

export default function () {
  store.commit("setup/SET_PRELOADER", true, { root: true });
  const modules = [
    "booking",
    "group",
    "permission",
    "role",
    "service",
    "assets",
    "resource",
    "store",
    "mobileEditor",
    "invoices",
    "newsletter",
    "customerNotification",
    "simpleStore",
    "subscriptions",
    "google",
    "microsoft",
    "integrations",
    "location",
    "bookingReminder",
  ];
  modules.forEach((module) => {
    if (store.hasModule(module)) {
      store.commit(`${module}/RESET_STATE`);
      store.unregisterModule(module);
    }
  });

  store.commit("setup/RESET_CONFIGURATION");
  store.commit("setup/RESET_ADMIN_CONFIGURATION");
  store.commit("setup/SET_PRELOADER", false, { root: true });
}
