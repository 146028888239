import { debounce } from "debounce";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import store from "@/store";
import { cloneObject } from "@/lib/calendesk-js-library/tools/helpers";
import sanitizeHtml from "@/lib/calendesk-js-library/filters/sanitizeHtml";

export default class EditorContentController {
  static generateFreshContent(selectedRecord) {
    const content = {
      email: null,
      push: null,
      sms: null,
    };

    if (selectedRecord.email) {
      const emailTemplate = EditorContentController.getTemplateFor(
        selectedRecord.templates,
        "email",
      );
      content.email = {
        subject: emailTemplate
          ? emailTemplate.subject
          : trans(
              `${selectedRecord.category}_${selectedRecord.type}_email_subject_${selectedRecord.receiver}`,
            ),
        text: emailTemplate
          ? emailTemplate.content
          : trans(
              `${selectedRecord.category}_${selectedRecord.type}_email_text_${selectedRecord.receiver}`,
            ),
      };
    }

    if (selectedRecord.push) {
      const pushTemplate = EditorContentController.getTemplateFor(
        selectedRecord.templates,
        "push",
      );
      content.push = {
        subject: pushTemplate
          ? pushTemplate.subject
          : trans(
              `${selectedRecord.category}_${selectedRecord.type}_push_subject_${selectedRecord.receiver}`,
            ),
        text: pushTemplate
          ? pushTemplate.content
          : trans(
              `${selectedRecord.category}_${selectedRecord.type}_push_text_${selectedRecord.receiver}`,
            ),
      };
    }

    if (selectedRecord.sms) {
      const smsTemplate = EditorContentController.getTemplateFor(
        selectedRecord.templates,
        "sms",
      );
      content.sms = {
        text: smsTemplate
          ? smsTemplate.content
          : trans(
              `${selectedRecord.category}_${selectedRecord.type}_sms_text_${selectedRecord.receiver}`,
            ),
      };
    }

    return content;
  }

  static getTemplateFor(templates, type) {
    if (templates && templates.length > 0) {
      const found = templates.find((template) => template.type.includes(type));

      return found || null;
    }

    return null;
  }

  static getFreshContent() {
    return EditorContentController.generateFreshContent(
      store.getters["notification/getEditorModalSelectedRecord"],
    );
  }

  static getPreviewContent() {
    return store.getters["notification/getEditorModalPreviewContent"];
  }

  static getTemplates() {
    return store.getters["notification/getTemplates"];
  }

  static getTemplateTypes() {
    return store.getters["notification/getTypes"];
  }

  static notificationTemplatesAPIAction(action) {
    return new Promise((resolve, reject) => {
      const selectedRecord =
        store.getters["notification/getEditorModalSelectedRecord"];
      const requests = [];

      const types = ["email", "sms", "push"];

      types.forEach((type) => {
        const key = `${type}.${selectedRecord.category}.${selectedRecord.type}.${selectedRecord.receiver}`;
        if (action === "update") {
          if (selectedRecord[type]) {
            requests.push(
              ...EditorContentController.prepareForUpdateOrCreate(type, key),
            );
          }
        } else if (selectedRecord[type]) {
          requests.push(...EditorContentController.prepareForDelete(type, key));
        }
      });

      Promise.all(requests)
        .then(() => {
          store.dispatch("notification/fetchTemplates").then(() => {
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static prepareForUpdateOrCreate(type, key) {
    const templates = EditorContentController.getTemplates();
    const template = templates
      ? templates.find((template) => template.type === key)
      : null;
    const content = EditorContentController.getPreviewContent();
    const requests = [];
    let filteredType = null;

    const payload = {
      content: sanitizeHtml(content[type].text),
    };

    if (type !== "sms") {
      payload.subject = sanitizeHtml(content[type].subject);
    }

    if (template) {
      payload.template_id = template.id;
      requests.push(
        store.dispatch("notification/updateNotificationTemplate", payload),
      );
    } else {
      const types = EditorContentController.getTemplateTypes();
      filteredType = types.find((t) => t.name === key);
      if (filteredType) {
        payload.type_id = filteredType.id;
        requests.push(
          store.dispatch("notification/createNotificationTemplate", payload),
        );
      }
    }

    return requests;
  }

  static prepareForDelete(type, key) {
    const templates = EditorContentController.getTemplates();
    const template = templates
      ? templates.find((template) => template.type === key)
      : null;

    if (template) {
      return [
        store.dispatch("notification/deleteNotificationTemplate", template.id),
      ];
    }

    return [];
  }
}

EditorContentController.setContent = debounce((type, subtype, content) => {
  const previewContent = cloneObject(
    EditorContentController.getPreviewContent(),
  );
  previewContent[type][subtype] = content;

  store.dispatch("notification/setEditorModalPreviewContent", previewContent);
}, 1000);
