import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import LoadScript from "vue-plugin-load-script";
import VueYouTubeEmbed from "vue-youtube-embed";
import VueGoogleCharts from "vue-google-charts";
import App from "./App.vue";

import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import { createGuard } from "./router/guards/createGuad";
import "./calendesk/filters/createFilters";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import mixpanel from "mixpanel-browser";
import "vuetify/dist/vuetify.min.css";
import "./plugins/vee-validate";
import "./styles/calendesk/index.scss";
import "@/lib/calendesk-js-library/styles/customHtmlSection.scss";
import "./lib/calendesk-js-library/prototypes/helpers";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale-subtle.css";

import { config } from "./lib/calendesk-js-library/prototypes/config";
import { adminConfig } from "@/lib/calendesk-js-library/prototypes/adminConfig";
import { trans } from "./lib/calendesk-js-library/prototypes/trans";
import { loading } from "./calendesk/prototypes/loading";

window.calendesk = {
  version: process.env.VUE_APP_APP_VERSION,
  env: process.env.NODE_ENV,
};

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: "bc36caaa8041d30a4e1f184c1f8acea7",
    appVersion: window.calendesk.version,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV,
    onError: function (event) {
      if (event.errors && event.errors[0]) {
        const firstError = event.errors[0];

        if (
          firstError.errorMessage.includes("Loading CSS chunk") ||
          firstError.errorMessage.includes("Loading chunk")
        ) {
          window.alert(
            "An error occurred while loading the page. The page will now reload. Error: " +
              firstError.errorMessage,
          );

          setTimeout(function () {
            location.reload();
          }, 4000);
        }
      }
    },
  });

  Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);
}

Vue.prototype.$trans = trans;
Vue.prototype.$config = config;
Vue.prototype.$adminConfig = adminConfig;
Vue.prototype.$loading = loading;
Vue.config.ignoredElements = ["zapier-app-directory"];

Vue.use(LoadScript);
Vue.use(VueYouTubeEmbed);
Vue.use(VueGoogleCharts);

if (window.CALENDESK_ENV === "production") {
  mixpanel.init("679c51a1134e360424cdc41cdfc90f31", {
    debug: false,
  });

  if (typeof gtag === "function") {
    gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
      personalization_storage: "granted",
      functionality_storage: "granted",
      security_storage: "granted",
    });

    gtag("event", "consentUpdated");
  }
}

router.beforeEach(createGuard("beforeGuard"));
router.afterEach(createGuard("afterGuard"));

new Vue({
  router,
  store,
  vuetify,
  moment,
  Bugsnag,
  render: (h) => h(App),
}).$mount("#app");
