import path from "./path";

const routeLocale = "/" + (localStorage.locale || "en") + "/";
export default [
  {
    path: path.home,
    redirect:
      (typeof localStorage.locale !== "undefined"
        ? localStorage.locale
        : "unknown") + path.home,
  },
  {
    name: "auth",
    path: "/:locale/auth",
    component: () => import("@/views/dashboard/pages/Dashboard/Auth"),
  },
  {
    name: "browser",
    path: `/:locale/browser`,
    component: () =>
      import("@/views/dashboard/pages/UserBrowserSettings/UserBrowserSettings"),
  },
  {
    name: "card3ds",
    path: "/:locale/3ds",
    component: () => import("@/views/dashboard/pages/Plans/3DS"),
  },
  {
    path: `/:locale/${path.panel}`,
    component: () => import("@/views/dashboard/Index"),
    meta: {
      auth: true,
    },
    children: [
      // Legacy support
      {
        name: "assets_old",
        path: "assets",
        redirect: routeLocale + "panel/tools/assets",
      },
      {
        name: "simple_store_old",
        path: "store",
        redirect: routeLocale + "panel/offer/products",
      },
      {
        name: "integrations_old",
        path: "profile/integrations",
        redirect: { name: "integrations" },
      },
      {
        name: "integrations_google_old",
        path: "profile/account/google/calendar/auth",
        redirect: routeLocale + "panel/integrations/google",
      },
      {
        name: "integrations_zoom_old",
        path: "profile/zoom/auth-url/callback",
        redirect: routeLocale + "panel/integrations/zoom",
      },
      {
        name: "stripe_old",
        path: "plugins/stripe",
        redirect: routeLocale + "panel/integrations/stripe",
      },
      {
        name: "fakturownia_old",
        path: "plugins/fakturownia",
        redirect: routeLocale + "panel/integrations/fakturownia",
      },
      {
        name: "payments_old",
        path: "profile/payments",
        redirect: routeLocale + "panel/billing/invoices",
      },
      {
        name: "bookings_calendar_old",
        path: "bookings/calendar",
        redirect: { name: "calendar" },
      },
      {
        name: "bookings_old",
        path: "bookings",
        redirect: routeLocale + "panel/sales/events",
      },
      {
        name: "locks_old",
        path: "bookings/locks",
        redirect: routeLocale + "panel/sales/events",
      },
      {
        name: "user_subscriptions_old",
        path: "customers/subscriptions",
        redirect: routeLocale + "panel/sales/subscriptions",
      },
      {
        name: "simple_store_transactions_old",
        path: "store/transactions",
        redirect: routeLocale + "panel/sales/products",
      },
      {
        name: "services_old",
        path: "services",
        redirect: routeLocale + "panel/offer/services",
      },
      {
        name: "add_category_old",
        path: "services/category/add",
        redirect: routeLocale + "panel/offer/services",
      },
      {
        name: "subscriptions_old",
        path: "subscriptions/list",
        redirect: routeLocale + "panel/offer/subscriptions",
      },
      {
        name: "customer_newsletter_old",
        path: "customers/newsletters",
        redirect: routeLocale + "panel/notifications/newsletter",
      },
      {
        name: "customer_notifications_old",
        path: "customers/notifications",
        redirect: routeLocale + "panel/notifications/activity",
      },
      {
        name: "websites_old",
        path: "websites",
        redirect: routeLocale + "panel/tools/websites",
      },
      {
        name: "plugins_old",
        path: "plugins/assets",
        redirect: routeLocale + "panel/tools/assets",
      },
      {
        name: "mobile_app_old",
        path: "plugins/mobile-drafts",
        redirect: routeLocale + "panel/tools/app",
      },
      {
        name: "settings_notifications_old",
        path: "settings/notifications",
        redirect: routeLocale + "panel/notifications/settings",
      },
      {
        name: "settings_notification_templates_old",
        path: "settings/notifications/templates",
        redirect: routeLocale + "panel/notifications/templates",
      },
      {
        name: "profile_account_old",
        path: "profile/account",
        redirect: routeLocale + "panel/settings/company",
      },
      {
        name: "profile_company_old",
        path: "profile/company",
        redirect: routeLocale + "panel/settings/company",
      },
      {
        name: "profile_packages_old",
        path: "profile/packages",
        redirect: routeLocale + "panel/billing/subscription",
      },
      {
        name: "tags_groups_resources_old",
        path: "settings/tags-groups-resources",
        redirect: routeLocale + "panel/settings/tags",
      },

      // Dashboard
      {
        name: "dashboard",
        path: path.dashboard,
        component: () => import("@/views/dashboard/pages/Dashboard/Dashboard"),
      },

      // Bookings
      {
        name: "calendar",
        path: path.calendar,
        component: () =>
          import("@/views/dashboard/pages/Calendar/MainCalendar"),
      },

      // Sales
      {
        name: "sales",
        path: `${path.sales.main}/:tab`,
        component: () => import("@/views/dashboard/pages/Sales/SalesWrapper"),
      },

      // Offer
      {
        name: "offer",
        path: `${path.offer.main}/:tab`,
        component: () => import("@/views/dashboard/pages/Offer/OfferWrapper"),
      },
      {
        name: "add_service",
        path: `${path.offer.services_add}/:duplicateId?`,
        meta: {
          title: "section_admin_panel_offer",
        },
        component: () =>
          import("@/views/dashboard/pages/Offer/Services/AddService"),
      },
      {
        name: "service",
        path: `${path.offer.services}/:id`,
        component: () =>
          import("@/views/dashboard/pages/Offer/Services/ServiceWrapper.vue"),
        props: true,
      },

      // Employees
      {
        name: "employees",
        meta: {
          title: "section_admin_panel",
        },
        path: path.employees.main,
        component: () =>
          import("@/views/dashboard/pages/Employees/EmployeeList"),
      },
      {
        name: "add_employee",
        meta: {
          title: "section_admin_panel_employees",
        },
        path: path.employees.add,
        component: () =>
          import("@/views/dashboard/pages/Employees/AddEmployee"),
      },
      {
        name: "employee",
        path: `${path.employees.main}/:id`,
        component: () => import("@/views/dashboard/pages/Employees/Employee"),
        props: true,
      },

      // Users
      {
        name: "users",
        meta: {
          title: "section_admin_panel",
        },
        path: path.customers.main,
        component: () => import("@/views/dashboard/pages/Users/UserList"),
      },
      {
        name: "add_user",
        meta: {
          title: "section_admin_panel_clients",
        },
        path: path.customers.add,
        component: () => import("@/views/dashboard/pages/Users/AddUser"),
      },
      {
        name: "user",
        path: `${path.customers.main}/:id`,
        component: () => import("@/views/dashboard/pages/Users/User"),
        props: true,
      },

      // Availability
      {
        name: "availability",
        meta: {
          title: "section_admin_panel",
        },
        path: path.availability.main,
        component: () =>
          import("@/views/dashboard/pages/Availability/AvailabilityList"),
      },

      // Tools
      {
        name: "tools",
        path: `${path.tools.main}/:tab`,
        component: () => import("@/views/dashboard/pages/Tools/ToolsWrapper"),
        props: true,
      },
      {
        name: "plugins_mobile_editor",
        path: `${path.tools.mobile_editor}/:id`,
        meta: {
          title: "section_admin_panel_tools",
        },
        component: () =>
          import("@/views/dashboard/pages/Tools/Mobile/MobileEditor"),
      },

      // Integrations
      {
        name: "integrations",
        meta: {
          title: "section_admin_panel",
        },
        path: `${path.integrations.main}/:integration?`,
        component: () =>
          import("@/views/dashboard/pages/Integrations/IntegrationTabs"),
        props: true,
      },

      // Notifications
      {
        name: "notifications",
        path: `${path.notifications.main}/:tab`,
        component: () =>
          import("@/views/dashboard/pages/Notifications/NotificationsWrapper"),
        props: true,
      },

      // Settings
      {
        name: "settings",
        path: `${path.settings.main}/:tab`,
        component: () =>
          import("@/views/dashboard/pages/Settings/SettingsWrapper"),
      },

      // Billing
      {
        name: "billing",
        path: `${path.billing.main}/:tab`,
        component: () =>
          import("@/views/dashboard/pages/Billing/BillingWrapper"),
      },
    ],
  },
  {
    path: `/:locale${path.home}`,
    component: () => import("@/views/pages/Index"),
    children: [
      {
        name: "login",
        path: path.home,
        component: () => import("@/views/pages/CLogin"),
      },
      {
        name: "register",
        path: path.register,
        component: () => import("@/views/pages/CRegister"),
      },
      {
        name: "reset_password",
        path: `:tenant/${path.password_reset}/:token`,
        component: () => import("@/views/pages/CLogin"),
        props: true,
      },
      {
        name: "account_activation",
        path: `:tenant/${path.account_activation}/:token`,
        component: () => import("@/views/pages/CLogin"),
        props: true,
      },
      {
        name: "tenant",
        path: ":tenant",
        component: () => import("@/views/pages/CLogin"),
        props: true,
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/pages/Index"),
    children: [
      {
        name: "error",
        path: "",
        component: () => import("@/views/pages/NotFound"),
      },
    ],
  },
];
