import SharedListWorker from "worker-loader!./shared-list.worker";
import * as Comlink from "comlink";

const worker = Comlink.wrap(new SharedListWorker());

export const setCacheTableData = worker.setCacheTableData;
export const getCachedTableData = worker.getCachedTableData;

export const setCacheTableExpandedData = worker.setCacheTableExpandedData;
export const getCachedTableExpandedData = worker.getCachedTableExpandedData;

export const clearCache = worker.clearCache;

export const clearAllCache = worker.clearAllCache;
