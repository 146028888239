export const stripSurroundingPTags = (value) => {
  if (!value) return "";

  // Convert the value to a string
  value = value.toString();

  // Check if the string starts with <p> and ends with </p>
  if (value.startsWith("<p>") && value.endsWith("</p>")) {
    // Remove the surrounding <p> and </p> tags
    return value.slice(3, -4).trim();
  }

  // Return the original string if it doesn't have surrounding <p> tags
  return value;
};
