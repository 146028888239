import api from "@/lib/calendesk-js-library/api/api";
import {
  employeePermissions,
  hasPermission,
} from "@/lib/calendesk-js-library/tools/helpers";

const getDefaultState = () => ({
  selectedEmployee: null,
  allEmployees: [],
  activeEmployees: [],
  whatsappRecentlyDisabledByUser: false,
  refreshEmployeeStatus: false,
});

const state = getDefaultState();
const getters = {
  getAllEmployees: (state) => state.allEmployees,
  getActiveEmployees: (state) => state.activeEmployees,
  getSelectedEmployee: (state) => state.selectedEmployee,
  getWhatsappRecentlyDisabledByUser: (state) =>
    state.whatsappRecentlyDisabledByUser,
  shouldRefreshEmployees: (state) => state.refreshEmployeeStatus,
};
const actions = {
  fetchActive({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .employees({
          status: "active",
          limit: 999999, // HACK:
        })
        .then(({ data }) => {
          commit("SET_ACTIVE_EMPLOYEES", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAll({ commit }, data) {
    if (!data) {
      data = {
        limit: 999999, // HACK:
      };
    }
    return new Promise((resolve, reject) => {
      api
        .employees(data)
        .then(({ data }) => {
          commit("SET_ALL_EMPLOYEES", data.data);
          const activeEmployees = data.data.filter(
            (employee) => employee.user.status === "active"
          );
          commit("SET_ACTIVE_EMPLOYEES", activeEmployees);

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchList(context, data) {
    if (!data) {
      data = {
        limit: 999999, // HACK:
      };
    }
    return new Promise((resolve, reject) => {
      api
        .employees(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  get({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      api
        .employee(id)
        .then(({ data }) => {
          commit("SET_SELECTED_EMPLOYEE", data);
          dispatch("user/setSelectedUser", data.user, { root: true });

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  add({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createEmployee(payload)
        .then(({ data }) => {
          dispatch("fetchAll").then(() => {
            resolve(data);
          });

          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateEmployee(payload, payload.id)
        .then(({ data }) => {
          dispatch("fetchAll").then(() => {
            resolve(data);
          });

          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateOwn({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateOwnEmployee(payload)
        .then(({ data }) => {
          if (hasPermission(employeePermissions.SHOW_EMPLOYEES)) {
            dispatch("fetchAll")
              .then(() => {
                resolve(data);
              })
              .catch(() => {
                resolve(data);
              });

            dispatch("cache/clearCache", "availability-list", {
              root: true,
            });
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployee(id)
        .then(({ data }) => {
          dispatch("fetchAll").then(() => {
            resolve(data);
          });

          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMultiple({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateMultipleEmployees(payload)
        .then(({ data }) => {
          dispatch("fetchAll").then(() => {
            resolve(data);
          });

          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMultiple({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteMultipleEmployees(payload)
        .then(({ data }) => {
          dispatch("fetchAll").then(() => {
            resolve(data);
          });

          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchFile(context, data) {
    return new Promise((resolve, reject) => {
      api
        .generateFileRecords("employees", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setWhatsappRecentlyDisabledByUser({ commit }, status) {
    commit("SET_WHATSAPP_RECENTLY_DISABLED_BY_USER", status);
  },
  refreshEmployees({ commit, dispatch }) {
    dispatch("cache/clearCache", "employee-list-v2", {
      root: true,
    });

    commit("SET_REFRESH_EMPLOYEES", true);
    setTimeout(() => {
      commit("SET_REFRESH_EMPLOYEES", false);
    }, 1000);
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ACTIVE_EMPLOYEES(state, data) {
    state.activeEmployees = data;
  },
  SET_ALL_EMPLOYEES(state, data) {
    state.allEmployees = data;
  },
  SET_SELECTED_EMPLOYEE(state, employee) {
    state.selectedEmployee = employee;
  },
  SET_WHATSAPP_RECENTLY_DISABLED_BY_USER(state, status) {
    state.whatsappRecentlyDisabledByUser = status;
  },
  SET_REFRESH_EMPLOYEES(state, payload) {
    state.refreshEmployeeStatus = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
