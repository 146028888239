import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  drafts: [],
});

const state = getDefaultState();
const getters = {
  drafts: (state) => state.drafts,
};
const actions = {
  fetchAll({ commit }, data) {
    if (!data) {
      data = { limit: 9999 };
    }
    return new Promise((resolve, reject) => {
      api
        .getMobileDrafts(data)
        .then(({ data }) => {
          commit("SET_DRAFTS", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateMobileDraft(data.draft_id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DRAFTS(state, drafts) {
    if (drafts && drafts.length > 0) {
      state.drafts = drafts;
    } else {
      state.drafts = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
