import api from "@/lib/calendesk-js-library/api/api";

const state = {
  snackbar: {
    timeout: 5000,
    color: "success",
    text: "default text",
    closable: true,
  },
  isSending: false,
  statsLoaded: false,
  drawer: true,
  onboarding: {
    open: false,
    title: null,
    title_2: null,
    subtitle: null,
    youtubeId: null,
    imageSrc: null,
    timageTooltip: null,
    confirmBtnText: null,
    confirmBtnAction: null,
    cancelBtnText: null,
    cancelBtnAction: null,
    loadButtonAfterConfirm: false,
  },
};

const getters = {
  getSnackbar: ({ snackbar }) => snackbar,
  getPreviewAsset: ({ previewAsset }) => previewAsset,
  getIsSending: (state) => state.isSending,
  getStatsLoaded: (state) => state.statsLoaded,
  getDrawer: (state) => state.drawer,
};
const actions = {
  setIsSending({ commit }, isSending) {
    commit("SET_IS_SENDING", isSending);
  },
  setStatsLoaded({ commit }, payload) {
    commit("SET_STATS_LOADED", payload);
  },
  fetchCompanies(context, data) {
    return new Promise((resolve, reject) => {
      api
        .searchForTenant(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};
const mutations = {
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar;
  },
  SET_IS_SENDING(state, isSending) {
    state.isSending = isSending;
  },
  SET_STATS_LOADED(state, loaded) {
    state.statsLoaded = loaded;
  },
  SET_DRAWER(state, drawer) {
    state.drawer = drawer;
  },
};

export { state, getters, actions, mutations };
