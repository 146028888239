import {
  getItem,
  setItem,
} from "@/lib/calendesk-js-library/tools/localStorageManager";

const getDefaultState = () => ({
  selectedDate: null,
  selectedEmployees: [],
  newClientModal: false,
  currentView: null,
});

const state = getDefaultState();
const getters = {
  getSelectedEmployees: (state) => state.selectedEmployees,
  getSelectedDate: (state) => state.selectedDate,
  getNewClientModal: (state) => state.newClientModal,
  getCurrentView: (state) => state.currentView,
};
const actions = {
  setNewClientModal({ commit }, payload) {
    commit("SET_NEW_CLIENT_MODAL", payload);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  SET_SELECTED_EMPLOYEES(state, selectedEmployees) {
    setItem("calendarSelectedEmployees", selectedEmployees, true, true);
    state.selectedEmployees = selectedEmployees;
  },
  SET_NEW_CLIENT_MODAL(state, value) {
    state.newClientModal = value;
  },
  SET_CURRENT_VIEW(state, view) {
    state.currentView = view;
  },
  RESTORE_CACHE(state) {
    state.selectedEmployees =
      getItem("calendarSelectedEmployees", true, true) || [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
