import { cloneObject } from "@/lib/calendesk-js-library/tools/helpers";
import {
  getItem,
  setItem,
} from "@/lib/calendesk-js-library/tools/localStorageManager";

import { clearAllCache, clearCache } from "@/workers/shared-list-worker-api";

const getDefaultState = () => ({
  tableFilters: null,
  tableOptions: null,
  cTabsState: null,
});

const state = getDefaultState();
const getters = {
  getCTabsState: (state) => {
    if (state.cTabsState) {
      return state.cTabsState;
    } else {
      const stored = getItem("cTabsState", true, true);
      if (stored) {
        return stored;
      }
    }

    return null;
  },
  getTableFilters: (state) => {
    if (state.tableFilters) {
      return state.tableFilters;
    } else {
      const stored = getItem("TableFilters", true, true);
      if (stored) {
        return stored;
      }
    }

    return null;
  },
  getTableOptions: (state) => {
    if (state.tableOptions) {
      return state.tableOptions;
    } else {
      const stored = getItem("TableOptions", true, true);
      if (stored) {
        return stored;
      }
    }

    return null;
  },
};
const actions = {
  setCTabsState({ state, commit }, data) {
    if (data && data.cacheId) {
      let cloned = {};

      if (state.cTabsState) {
        cloned = cloneObject(state.cTabsState);
      }

      cloned[data.cacheId] = cloneObject(data.data);

      commit("SET_C_TABS_STATE", cloned);

      setItem("cTabsState", cloned, true, true);
    }
  },
  async clearCache({ state, commit, rootGetters }, cacheId) {
    let cloned = {};

    if (state.tableFilters) {
      cloned = cloneObject(state.tableFilters);
      delete cloned[cacheId];
      commit("SET_TABLE_FILTERS", cloned);
    }

    if (state.tableOptions) {
      cloned = cloneObject(state.tableOptions);
      delete cloned[cacheId];
      commit("SET_TABLE_OPTIONS", cloned);
    }

    const tenantId = rootGetters["setup/getTenant"];
    if (tenantId) {
      cacheId = `${tenantId}_${cacheId}`;
    }

    await clearCache(cacheId);
  },
  setTableFilters({ commit, state }, data) {
    if (data && data.cacheId) {
      let cloned = {};

      if (state.tableFilters) {
        cloned = cloneObject(state.tableFilters);
      }

      cloned[data.cacheId] = cloneObject(data.data);

      commit("SET_TABLE_FILTERS", cloned);

      setItem("TableFilters", cloned, true, true);
    }
  },
  setTableOptions({ commit, state }, data) {
    if (data && data.cacheId) {
      let cloned = {};

      if (state.tableOptions) {
        cloned = cloneObject(state.tableOptions);
      }

      cloned[data.cacheId] = data.data;
      commit("SET_TABLE_OPTIONS", cloned);

      setItem("TableOptions", cloned, true, true);
    }
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
    clearAllCache();
  },
  RESTORE_CACHE(state) {
    state.tableFilters = getItem("TableFilters", true, true);
    state.tableOptions = getItem("TableOptions", true, true);
  },
  SET_TABLE_FILTERS(state, data) {
    state.tableFilters = data;
  },
  SET_TABLE_OPTIONS(state, data) {
    state.tableOptions = data;
  },
  SET_C_TABS_STATE(state, tabState) {
    state.cTabsState = tabState;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
